import React, { useContext, useEffect } from 'react'
import './Taxiform.css'
import {  useFormik } from 'formik';
import * as yup from 'yup';
import {Tooltip } from '@mui/material';
import MyContext from '../../../Common/Context/MyContext';


const TaxiForm = () => {

  const Token = sessionStorage.getItem('token')

  const{Navigate,setBooking,setPayment,ldata,startDestination,setStartDestination,endDestination,setEndDestination} = useContext(MyContext)


  useEffect(() => {
    formik.setValues({
      ...formik.values,
      pickuppoint: startDestination,
      droppoint: endDestination,
    });
  }, [startDestination, endDestination]);
  
   
   
  

   

        const formik = useFormik({
            initialValues: {
                name: '',
                email: '',
                mobile: '',
                selecttaxi:'',
                numofpass:'',
                pickuppoint: startDestination,
                droppoint:endDestination,
                pickupdate: '',
                pickuptime: '',
                numofbags:'',


            },
            
            validationSchema:yup.object({
              name: yup
                  .string('* Enter your Name')
                  .required('* Name is required')
                  .matches((/^([^0-9]*)$/), "* don't enter numeric Value")
                  .min(4, '* Name should atleast 4 charcter'),
  
              email: yup
                  .string('* Enter your email')
                  .matches(/^\S*$/, '* email cannot contain whitespace')
                  .email('* Enter a valid email')
                  .required('* Email is required'),
  
              mobile: yup
                  .string('* Enter your mobile no')
                  .matches(/^\S*$/, '* Mobile no cannot contain whitespace')
                  .min(10, '* Mobile no should be 10 Number')
                  .max(12, '* Mobile no should be 10 Number')
                  .required('* Mobile no is required')
                  .matches(/^(\+?\d{1,3}[- ]?)?\d{10}$/, '* Mobile number is not valid'),
  
              pickuppoint: yup
                  .string('* enter pickup point')
                  .matches((/^([^0-9]*)$/), "* donn't enter numeric Value")
                  .required('* Enter pickup point')
                  .test('notSameAsDrop', '*Pickup point cannot be the same as drop point', function (value) {
                    return value !== this.parent.droppoint;
                  }),
  
              droppoint: yup
                  .string('* Enter drop point')
                  .matches((/^([^0-9]*)$/), "* donn't enter numeric Value")
                  .required('* Enter drop point'),
  
              pickupdate: yup
                  .string('* Select pickup date')
                  .required('* Select pickup date'),
  
              pickuptime: yup
                  .string('* Select pickup time')
                  .required('* Select pickup time'),
  
              selecttaxi: yup
                  .string('* Select taxi')
                  .required('* Select taxi'),
              
              numofpass: yup
                  .string('* Select number of passangers')
                  .required('* Select number of passangers'),
              
              numofbags:yup
              .string('*Select number of Bags')
              .required('*Select Number of Bags'),
  
  
          }),
            onSubmit: async (values, { resetForm }) => {
              setBooking(values)
              setPayment(true)
              
             
        
            
             

             
             
            },
            

        });

        return (
          <>
            <div className="form-container">
            {!Token && <div className="over-lay">Login First <span onClick={() => Navigate('/login')}>click here</span></div>}
            <div className="form-right">
              <h4>Online Booking</h4>
              <h1>Book Your Taxi</h1>
              <form className="mainform" onSubmit={formik.handleSubmit}>
                <span>
                  <Tooltip title="Your Name" placement="top">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Your Name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Tooltip>
      
                  {formik.errors.name && formik.touched.name ? (
                    <p className="form-error">{formik.errors.name}</p>
                  ) : null}
                </span>
                <span>
                  <Tooltip title="Email ID" placement="top">
                    <input
                      type="text"
                      name="email"
                      id="email"
                      placeholder="Email Id"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                    />
                  </Tooltip>
                  {formik.errors.email && formik.touched.email ? (
                    <p className="form-error">{formik.errors.email}</p>
                  ) : null}
                </span>
      
                <span>
                  <Tooltip title="Mobile No" placement="top">
                    <input
                      type="text"
                      name="mobile"
                      id="mobile"
                      placeholder="Mobile No"
                      value={formik.values.mobile}
                      onChange={formik.handleChange}
                    />
                  </Tooltip>
                  {formik.errors.mobile && formik.touched.mobile ? (
                    <p className="form-error">{formik.errors.mobile}</p>
                  ) : null}
                </span>
      
                <span>
                  <Tooltip title="Select Taxi" placement="top">
                    <select
                      type="select"
                      name="selecttaxi"
                      id="selecttaxi"
                      value={formik.values.selecttaxi}
                      onChange={formik.handleChange}
                    >
                      <option value="" disabled>Select Taxi</option>
                      <option value="Normal Taxi">
                        <p>Normal Taxi</p>
                      </option>
                      <option value="Town Taxi">Town Taxi</option>
                      <option value="Suv Taxi">Suv Taxi</option>
                      <option value="Limousine Taxi">Limousine Taxi</option>
                    </select>
      
                    {formik.errors.selecttaxi && formik.touched.selecttaxi ? (
                      <p className="form-error">{formik.errors.selecttaxi}</p>
                    ) : null}
                  </Tooltip>
                </span>
      
                <span>
                  <Tooltip title="Select number of passanger" placement="top">
                    <select
                      type="select"
                      name="numofpass"
                      id="numofpass"
                      value={formik.values.numofpass}
                      onChange={formik.handleChange}
                    >
                      <option value="" disabled>Num of Passangers</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </select>
                    {formik.errors.numofpass && formik.touched.numofpass ? (
                      <p className="form-error">{formik.errors.numofpass}</p>
                    ) : null}
                  </Tooltip>
                </span>
      
                <span>
                  <Tooltip title="Start Destination" placement="top">
                    <input
                      type="text"
                      name="pickuppoint"
                      id="pickuppoint"
                      placeholder="Start Destination"
                      value={formik.values.pickuppoint}
                    
                      onChange={(e) => setStartDestination(e.target.value)}

            
                    />
                  </Tooltip>
                  {formik.errors.pickuppoint && formik.touched.pickuppoint ? (
                    <p className="form-error">{formik.errors.pickuppoint}</p>
                  ) : null}

                  <div className='dropdown'>
                  {
                    ldata
                    .filter((b) =>{
                      const nawinput = startDestination.toLowerCase()
                    const mjson = b.city +' '+b.state+' '+'india'
                      const newjson = mjson.toLowerCase()
                      return(
                        nawinput &&
newjson.startsWith(nawinput)
                      )
                    })
                    .slice(0,7)
                    .map((a) =>{
                      return(
                        <li onClick={() => setStartDestination(`${a.city}, ${a.state}, India`)}>{`${a.city}, ${a.state}, India`}</li>

                      )
                    })
                  }
                  </div>
                </span>
      
                <span>
                  <Tooltip title="End Destination" placement="top">
                    <input
                      type="text"
                      name="droppoint"
                      id="droppoint"
                 
                      value={formik.values.droppoint}
                      onChange={(e) => setEndDestination(e.target.value)}
                      placeholder="End Destination"
                   
                    />
                  </Tooltip>
                  {formik.errors.droppoint && formik.touched.droppoint ? (
                    <p className="form-error">{formik.errors.droppoint}</p>
                  ) : null}

                  <div className='dropdown'>
                  {
                    ldata
                    .filter((b) =>{
                      const nawinput = endDestination.toLowerCase()
                    const mjson = b.city +' '+b.state+' '+'india'
                      const newjson = mjson.toLowerCase()
                      return(
                        nawinput &&
newjson.startsWith(nawinput)
                      )
                    })
                    .slice(0,7)
                    .map((a) =>{
                      return(
                        <li onClick={() => setEndDestination(`${a.city}, ${a.state}, India`)}>{`${a.city}, ${a.state}, India`}</li>

                      )
                    })
                  }
                  </div>
                </span>
      
                <span>
                  <Tooltip title="Select pickup date" placement="top">
                    <input
                      type="date"
                      name="pickupdate"
                      id="pickupdate"
                      placeholder="Select Date"
                      value={formik.values.pickupdate}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.pickupdate && formik.touched.pickupdate ? (
                      <p className="form-error">{formik.errors.pickupdate}</p>
                    ) : null}
                  </Tooltip>
                </span>
      
                <span>
                  <Tooltip title="select pickup time" placement="top">
                    <input
                      type="time"
                      name="pickuptime"
                      id="pickuptime"
                      placeholder="Select time"
                      value={formik.values.pickuptime}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.pickuptime && formik.touched.pickuptime ? (
                      <p className="form-error">{formik.errors.pickuptime}</p>
                    ) : null}
                  </Tooltip>
                </span>
      
                <span>
                  <Tooltip title="Select number of bags" placement="top">
                    <select
                      type="select"
                      name="numofbags"
                      id="numofbags"
                      value={formik.values.numofbags}
                      onChange={formik.handleChange}
                    >
                      <option value="" disabled>Num of Bags</option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </select>
                    {formik.errors.numofbags && formik.touched.numofbags ? (
                      <p className="form-error">{formik.errors.numofbags}</p>
                    ) : null}
                  </Tooltip>
                </span>

                        <button className='fsubmit-btn' type="submit">
                     Submit
                        </button>

                    </form>
   
  
      
                </div>

            </div>


          
       
            
            </>
    )
}


export default TaxiForm