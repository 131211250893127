import React from 'react'
import "./Wishlist.css"
import {useState,useEffect} from 'react';


const Wishlist = ({ cart }) => {
  const [CART, setCART] = useState([])

  useEffect(() => {
    setCART(cart)
  }, [cart])
  return (
    <div>
      {CART.map((cartitem) => {
        return (

          <div className='tripcard-container'>
            <div className='tripcard'>
              <img src={cartitem.avatar_url} alt="" />
              <h6>{cartitem.title}</h6>
            </div>
            <h5>{cartitem.Distance}Km</h5>


          </div>

        )
      })}



    </div>
  )
}

export default Wishlist