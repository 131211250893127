import React, { useContext, useEffect} from "react";
import "./Registervehicle.css";
import { useFormik } from "formik";
import * as yup from "yup";
import {  Tooltip } from "@mui/material";
import MyContext from "../../Common/Context/MyContext";

const Registervehicle = () => {

  const {loading,setOpen,ldata,setLoading,setMessage,startDestination,setStartDestination} = useContext(MyContext)
 
  useEffect(() => {
    formik.setValues({
      ...formik.values,
      city: startDestination,
     
    });
  }, [startDestination]);


  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile: "",
      carname: "",
      carmodel: "",
      licensenumber: "",
      vehicleyear: "",
      vehiclecolor: "",
      city: startDestination,
      puc: false,
      drivinglicense: false,
      rcbook: false,
    },

    validationSchema:yup.object({
      name: yup
        .string("* Enter your Name")
        .required("* Name is required")
        .min(4, "* Name should atleast 4 charcter"),
  
      email: yup
        .string("* Enter your email")
        .matches(/^\S*$/, "* email cannot contain whitespace")
        .email("* Enter a valid email")
        .required("* Email is required"),
  
      mobile: yup
        .string("* Enter your mobile no")
        .matches(/^\S*$/, "* Mobile no cannot contain whitespace")
        .min(10, "* Mobile no should be 10 Number")
        .max(12, "* Mobile no should be 10 Number")
        .required("* Mobile no is required")
        .matches(/^(\+?\d{1,3}[- ]?)?\d{10}$/, "* Mobile number is not valid"),
  
      licensenumber: yup
        .string("* license number required")
        .required("*license number required"),
  
      vehicleyear: yup
        .string("* vehicle year required")
        .required("* vehicle year required"),
  
      vehiclecolor: yup
        .string("* vehicle color required")
        .required("* vehicle color required"),
  
      carname: yup
        .string("* car name required")
        .required("* car name required"),
  
      carmodel: yup
        .string("* car model required")
        .required("* car model required"),
  
      city: yup.string("*city required").required("*city require"),
  
      puc: yup.boolean().oneOf([true], <pre>*</pre>),
      drivinglicense: yup.boolean().oneOf([true], <pre>*</pre>),
      rcbook: yup.boolean().oneOf([true], <pre>*</pre>),
    }),
    onSubmit: async (values, { resetForm }) => {
      setLoading(true)
      const response = await fetch('https://backend-nine-vert.vercel.app/register-vehicle', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      const data = await response.json();

      if (data.success) {
        setMessage(data.message)
       setOpen(true)
        resetForm();
        setTimeout(() => {
        window.location.reload()
        }, 1500);
       

      } else {
        setMessage(data.error)
        setOpen(true)
      }

      setLoading(false); 
  },
  });
  return (
    <div className="form-container">
      <div className="form-right">
        <h4>Register Vehicle</h4>
        <h1>Register Your Vehicle</h1>
        <form className="mainform" onSubmit={formik.handleSubmit}>
          <span>
            <Tooltip title="Your Name" placement="top">
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Your Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Tooltip>

            {formik.errors.name && formik.touched.name ? (
              <p className="form-error">{formik.errors.name}</p>
            ) : null}
          </span>
          <span>
            <Tooltip title="Email ID" placement="top">
              <input
                type="text"
                name="email"
                id="email"
                placeholder="Email Id"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
            </Tooltip>
            {formik.errors.email && formik.touched.email ? (
              <p className="form-error">{formik.errors.email}</p>
            ) : null}
          </span>
          <span>
            <Tooltip title="Mobile No" placement="top">
              <input
                type="text"
                name="mobile"
                id="mobile"
                placeholder="Mobile No"
                value={formik.values.mobile}
                onChange={formik.handleChange}
              />
            </Tooltip>
            {formik.errors.mobile && formik.touched.mobile ? (
              <p className="form-error">{formik.errors.mobile}</p>
            ) : null}
          </span>
          <span>
            <Tooltip title="car name" placement="top">
              <input
                type="text"
                placeholder="Car Name"
                name="carname"
                id="carname"
                value={formik.values.carname}
                onChange={formik.handleChange}
              />
            </Tooltip>

            {formik.errors.carname && formik.touched.carname ? (
              <p className="form-error">{formik.errors.carname}</p>
            ) : null}
          </span>
          <span>
            <Tooltip title="car model" placement="top">
              <input
                type="text"
                placeholder="Car Model"
                name="carmodel"
                id="carmodel"
                value={formik.values.carmodel}
                onChange={formik.handleChange}
              />
            </Tooltip>

            {formik.errors.carmodel && formik.touched.carmodel ? (
              <p className="form-error">{formik.errors.carmodel}</p>
            ) : null}
          </span>
          <span>
            <Tooltip title="license number" placement="top">
              <input
                type="text"
                name="licensenumber"
                id="licensenumber"
                placeholder="License Number"
                value={formik.values.licensenumber}
                onChange={formik.handleChange}
              />
            </Tooltip>
            {formik.errors.licensenumber && formik.touched.licensenumber ? (
              <p className="form-error">{formik.errors.licensenumber}</p>
            ) : null}
          </span>
          <span>
            <Tooltip title="vehicle year" placement="top">
              <input
                type="text"
                name="vehicleyear"
                id="vehicleyear"
                placeholder="Vehicle Year"
                value={formik.values.vehicleyear}
                onChange={formik.handleChange}
              />
            </Tooltip>
            {formik.errors.vehicleyear && formik.touched.vehicleyear ? (
              <p className="form-error">{formik.errors.vehicleyear}</p>
            ) : null}
          </span>
          <span>
            <Tooltip title="vehicle color" placement="top">
              <input
                type="text"
                name="vehiclecolor"
                id="vehiclecolor"
                placeholder="Vehicle Color"
                value={formik.values.vehiclecolor}
                onChange={formik.handleChange}
              />
            </Tooltip>
            {formik.errors.vehiclecolor && formik.touched.vehiclecolor ? (
              <p className="form-error">{formik.errors.vehiclecolor}</p>
            ) : null}
          </span>
          <span>
            <Tooltip title="city" placement="top">
              <input
                type="text"
                name="city"
                placeholder="city + state + country"
                id="city"
 onChange={(e) => setStartDestination(e.target.value)}
                value={formik.values.city}
        
              />
            </Tooltip>

            {formik.errors.city && formik.touched.city ? (
              <p className="form-error">{formik.errors.city}</p>
            ) : null}
            <div className='dropdown'>
                  {
                    ldata
                    .filter((b) =>{
                      const nawinput = startDestination.toLowerCase()
                    const mjson = b.city +' '+b.state+' '+'india'
                      const newjson = mjson.toLowerCase()
                      return(
                        nawinput &&
newjson.startsWith(nawinput)
                      )
                    })
                    .slice(0,7)
                    .map((a) =>{
                      return(
                        <li onClick={() => setStartDestination(`${a.city}, ${a.state}, India`)}>{`${a.city}, ${a.state}, India`}</li>

                      )
                    })
                  }
                  </div>
          </span>
          <span className="span-checb">
            Documents
            <div className="divpuc">
              <input
                type="checkbox"
                name="puc"
                id="puc"
                checked={formik.values.puc}
                className="puc"
              
                onChange={formik.handleChange}
              />
              <h6 className="puc-head">
                <pre>do you have puc</pre>
              </h6>
              <div className="error-div">
                {formik.errors.puc && formik.touched.puc ? (
                  <p className="form-error">{formik.errors.puc}</p>
                ) : null}
              </div>
            </div>
            <div className="divdl">
              <input
                type="checkbox"
                name="drivinglicense"
                id="drivinglicense"
                checked={formik.values.drivinglicense}
                className="d-license"
                onChange={formik.handleChange}
              />
              <h6 className="puc-head">
                <pre>do you have driving license</pre>
              </h6>
              {formik.errors.drivinglicense && formik.touched.drivinglicense ? (
                <p className="form-error">{formik.errors.drivinglicense}</p>
              ) : null}
            </div>
            <div className="divrc">
              <input
                type="checkbox"
                name="rcbook"
                id="rcbook"
                checked={formik.values.rcbook}
                className="rcbook"
                onChange={formik.handleChange}
              />
              <h6 className="puc-head">
                <pre>do you have rcbook</pre>
              </h6>
              {formik.errors.rcbook && formik.touched.rcbook ? (
                <p className="form-error">{formik.errors.rcbook}</p>
              ) : null}
            </div>
          </span>
          <button className='fsubmit-btn' type="submit" >
                        {loading ? 'wait...' : 'Submit'}
                        </button>

                    </form>
      
      </div>
    </div>
  );
};

export default Registervehicle;