import React, { useEffect, useContext } from 'react';
import './Booking.scss';
import MyContext from '../Context/MyContext';

const Booking = () => {
  const Token = sessionStorage.getItem('token');
  const { binfo, setDataload, setBinfo, dataload, Navigate, setMessage, setOpen } = useContext(MyContext);

  useEffect(() => {
    if (!Token) {
      setMessage("Please login to view the bookings");
      setOpen(true);
      setTimeout(() => {
        Navigate('/login');
      }, 3000);
    }

    const fetchBookings = async () => {
      try {
        const response = await fetch('https://backend-nine-vert.vercel.app/book-info', {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch bookings');
        }
        const data = await response.json();
        setBinfo(data.bookInfo);
        setDataload(false);
      } catch (error) {
        console.error('Error fetching bookings:', error);
        setDataload(false);
      }
    };
    fetchBookings();
  }, [Token, setBinfo, setOpen, setDataload, Navigate, setMessage]);

  return (
<>
    { Token ?
    <div className="booking-container">
      <h2>Booking Details</h2>
      {dataload ? (
        <p>Loading...</p>
      ) : (
        <div className="table-container">
          <table className="booking-table">
            <thead>
              <tr>
                <th>Booking Date</th>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile</th>
                <th>Taxi Type</th>
                <th>Total Persons</th>
                <th>Pickup Point</th>
                <th>Drop Point</th>
                <th>Pickup Date</th>
                <th>Pickup Time</th>
                <th>Num Of Bags</th>
              </tr>
            </thead>
            <tbody>
              {binfo.length > 0 ? (
                binfo.sort((a, b) => b._id.localeCompare(a._id)).map((booking, index) => (
                  <tr key={index}>
                    <td>{new Date(booking.currentDate).toLocaleDateString('en-GB')}</td>
                    <td>{booking.name}</td>
                    <td>{booking.email}</td>
                    <td>{booking.mobile}</td>
                    <td>{booking.selecttaxi}</td>
                    <td>{booking.numofpass}</td>
                    <td>{booking.pickuppoint}</td>
                    <td>{booking.droppoint}</td>
                    <td>{new Date(booking.pickupdate).toLocaleDateString('en-GB')}</td>
                    <td>{booking.pickuptime}</td>
                    <td>{booking.numofbags}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="11">Booking is empty</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>:
    Navigate('/login')
    }
    </>
  );
};

export default Booking;
