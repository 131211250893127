import React, { useContext } from 'react';
import './loader.css';
import MyContext from '../Context/MyContext';

const Loader = () => {

  const {loading} = useContext(MyContext)
  return (
<>
{

loading &&

<div class="loader-main">
<div class="loader"></div>
</div>
}
    </>
  );
};

export default Loader;
