import React, { useState, useEffect } from "react";
import "./Ourservice.css";
import axios from "axios";

const Ourservice = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    axios
      .get("https://backend-nine-vert.vercel.app/api/ourservicee")
      .then((a) => setData(a.data));
  });

  const [height, setHeight] = useState("0px");

  const handlescroll = () => {
    const scroll = window.scrollY;
    if (scroll > 300) {
      setHeight("100%");
    } else {
      setHeight("0px");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handlescroll);
  });

  return (
    <div className="service">
      <div className="servce">
        <h4>Services</h4>
        <h1>Our best service for you</h1>
      </div>
      <div className="servicecard-container">
        {data.map((item, index) => (
          <div className="service-card" key={item.id}>
            <h4>{item.sname}</h4>
            <p>{item.sp}</p>
            <div className="s-readmore">Read More</div>
            <div className="overlay" style={{ height }}>
              <h4>{item.sname}</h4>
              <p>{item.sp}</p>
              <div className="s-readmore">Read More</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Ourservice;