import React, { useContext } from 'react';
import "./Register.css";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link } from 'react-router-dom';
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useFormik } from 'formik';
import * as yup from 'yup';
import MyContext from '../../Common/Context/MyContext';



const Register = () => {

  const Token = sessionStorage.getItem('token')
 


const {loading,setOpen,setLoading,setMessage,Navigate} = useContext(MyContext)

 

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };




  const formik = useFormik({
    initialValues: {
      name: '',
      mobile: '',
      email: '',
      password: '',
      termsAndConditions: false,
    },
    validationSchema:yup.object({
      name: yup
        .string('Enter your Name')
        .required('*Name is required')
        .min(4, 'Name should atleast 4 characters')
        .matches((/^([^0-9]*)$/), "Don't enter numeric Value"),
  
      mobile: yup
        .string('Enter your mobile no')
        .matches(/^\S*$/, 'Mobile no cannot contain whitespace')
        .matches((/^([0-9]*)$/), "Enter only numeric Value")
        .min(10, 'Mobile no should be 10 Numbers')
        .max(12, 'Mobile no should be 10 Numbers')
        .required('*Mobile no is required')
        .matches(/^(\+?\d{1,3}[- ]?)?\d{10}$/, 'Mobile number is not valid'),
  
      email: yup
        .string('Enter your email')
        .matches(/^\S*$/, 'Email cannot contain whitespace')
        .email('Enter a valid email')
        .required('*Email is required'),
  
      password: yup
        .string('Enter the password')
        .min(8, 'Password should be atleast 8 characters')
        .required('*Password is required'),
  
      termsAndConditions: yup
        .boolean()
        .oneOf([true], "** You must accept the terms and conditions")
        .required("** You must accept the terms and conditions"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setLoading(true)

      const response = await fetch('https://backend-nine-vert.vercel.app/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      const data = await response.json();

      if (data.success) {
        setMessage(data.message)
       setOpen(true)
        resetForm();
        setTimeout(() => {
          Navigate('/login')
        }, 1500);
       

      } else {
        setMessage(data.error)
        setOpen(true)
      }

      setLoading(false); 
    },
  });

  return (
    <> {!Token?
    <div className='R1'>

      <form className='registerf' onSubmit={formik.handleSubmit}>
        <i className='arrowback'><Link to='/login'><AiOutlineArrowLeft /></Link></i>
        <h1>Register</h1>
        <div className='rtext'>
          <TextField
            className='t1'
            name="name"
            label="Name"
            id="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            variant="outlined" />
          <TextField
            className='t1'
            name="mobile"
            label="Mobile No"
            id="mobile"
            value={formik.values.mobile}
            onChange={formik.handleChange}
            error={formik.touched.mobile && Boolean(formik.errors.mobile)}
            helperText={formik.touched.mobile && formik.errors.mobile}
            variant="outlined" />
          <TextField
            className='t1'
            name="email"
            label="Email"
            id="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            variant="outlined" />

          <TextField className="t1" label="Password" placeholder="Password"
            // id="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }} />

            <FormControlLabel
            control={<Checkbox
                name="termsAndConditions"
                id="termsAndConditions"
                checked={formik.values.termsAndConditions}
                onChange={formik.handleChange}
                color="primary"
            />}
            label="Terms & Conditions"
        />
        {formik.touched.termsAndConditions && formik.errors.termsAndConditions ? (
            <div>{formik.errors.termsAndConditions}</div>
        ) : null}
          <button className='registerbtn my-3' type="submit" >
            {loading ? 'wait...': 'Submit'}
          </button>


        </div>

      </form>
   


    </div>:

    Navigate('/')
      }

    </>
  );
}

export default Register;
