import React, { useContext, useEffect } from 'react'

import MyContext from '../Context/MyContext';
import "./Modal.css"
import { FaCar } from 'react-icons/fa6';
import { IoMdAlert } from 'react-icons/io';

const Modalc = () => {

    const{open,setOpen,message} = useContext(MyContext)

    useEffect(() => {
      if(open){
        setTimeout(() => {
            setOpen(false)
        }, 3000);
      }
     
    }, [open, setOpen]);
  return (
    <div>
    { open &&

        <div class="alert" style={{backgroundColor:message.includes("Thanks")?'green':'red'}}>
       <span>{message.includes("Thanks")?<FaCar/>:<IoMdAlert/>}</span>
        {message}
      </div>
   
    }
  </div>
  )
}

export default Modalc