import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import './Accfrm.css'; // Import external CSS file

const initialValues = {
  name: '',
  email: '',
  mobile: '',
  password: '',
};

const validateForm = values => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Required';
  }
  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.mobile) {
    errors.mobile = 'Required';
  } else if (!/^\d{10}$/i.test(values.mobile)) {
    errors.mobile = 'Invalid mobile number';
  }
  if (!values.password) {
    errors.password = 'Required';
  }
  return errors;
};

const handleUpdate = () => {
  // Add your update logic here
  console.log("Update button clicked!");
};

const Accfrm = () => (
    <div className="main">
  <div className="frm">
    <h1>Account Page</h1>
    <Formik
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={() => {}}
    >
      {() => (
        <Form className="formik-form">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <Field type="text" name="name" className="form-field" />
            <ErrorMessage name="name" component="div" className="error" />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <Field type="email" name="email" className="form-field" />
            <ErrorMessage name="email" component="div" className="error" />
          </div>
          <div className="form-group">
            <label htmlFor="mobile">Mobile</label>
            <Field type="text" name="mobile" className="form-field" />
            <ErrorMessage name="mobile" component="div" className="error" />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <Field type="password" name="password" className="form-field" />
            <ErrorMessage name="password" component="div" className="error" />
          </div>
        </Form>
      )}
    </Formik>
    <button type="button" onClick={handleUpdate} className="update-button">Update</button>
  </div>
  </div>
);

export default Accfrm;