import React, { useEffect, useState } from "react";
import "./Service.css";

import Ourservice from "./Our_service/Ourservice";

import "react-awesome-button/dist/styles.css";
import axios from "axios";

const Service = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    axios.get("https://backend-nine-vert.vercel.app/api/service").then((a) => setData(a.data));
  });
  return (
    <div>
      <div className="service-1">
        <h1>Service</h1>
      </div>
      <Ourservice />

      <div className="servicebox">
        <h1 style={{padding:"10px",fontSize:"29px"}}>Enjoy our Service</h1>

        <div className="gridbox">
          {data.map((my) => {
            return (
              <div className="card-main">
                <img className="avatarimg" src={my.avatar_url} alt="" />
                <div className="overlay">
                  <h3 className="ser-title">{my.title}</h3>
                  <h3 className="servh4">({my.Distance}km)</h3>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Service;