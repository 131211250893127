import React, { useContext } from 'react'
import './ConfirmPage.scss'
import MyContext from '../Context/MyContext'

const Confirm = () => {
const Token = sessionStorage.getItem('token')
    const {Navigate} = useContext(MyContext)
  return (
<>
    {Token ?
        <div class="order-page">
        <img src="https://i.ibb.co/FwbHGFk/green.png" alt=""/>
        <h3>Your Order Has Been Confirmed</h3>
    </div>:

   
    
    Navigate('/login')

    }
    </>
   
  )
}

export default Confirm