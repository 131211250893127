import React, { useContext, useState } from "react";
import "./LoginRegister.css";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useFormik } from 'formik';
import * as yup from 'yup';
import MyContext from "../../Common/Context/MyContext";

const Login = () => {

  const Token = sessionStorage.getItem('token')
const {loading,setLoading,setMessage,Navigate,setOpen, handleLogin} = useContext(MyContext)


  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };



  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: yup.object({
      email: yup
        .string('Enter your email')
        .matches(/^\S*$/, 'Email cannot contain whitespace')
        .email('Enter a valid email')
        .required('*Email is required'),
      password: yup
        .string('Enter the password')
        .min(8, 'Password should be at least 8 characters')
        .required('*Password is required'),
    }),
    onSubmit: async (values, { resetForm }) => {
      setLoading(true)
      const response = await fetch('https://backend-nine-vert.vercel.app/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      const data = await response.json();

      if (data.success) {
        setMessage(data.message)
        handleLogin(data.data,data.name)
       setOpen(true)
        resetForm();
      Navigate('/')
    
      
       

      } else {
        setMessage(data.error)
        setOpen(true)
      }

      setLoading(false); 

      
    },
  });

  return (

    <>
{!Token?
    <div className="l1">
      <form className="loginf" onSubmit={formik.handleSubmit}>
        <div className="tfield">
          <h1 className="my">Login </h1>
          <div className="emailpass">
            <TextField
              className="t1"
              name="email"
              label="Email"
              id="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              variant="outlined"
            />
            <TextField
              className="t1"
              label="Password"
              placeholder="Password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <button type="submit" className="loginbtn" disabled={loading}>
            {loading ? 'wait...' : 'Login'}
          </button>

        {/*  <p>OR </p>
          <div type="submit" className="googlebtn">
           
         Forgot password
          </div>
            */ }

         {!loading ? <p>New User ? <Link to="/register">Register here</Link></p>:null}
          <br /><br />
        </div>
      </form>
    </div>:

    Navigate('/')
            }

    </>
  );
};

export default Login;
