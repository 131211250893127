import React from 'react'
import './About.css'
import abt from '../../../../Assets/Images/Abouthm/car-big-2.png'
import Typewriter from "typewriter-effect";

const About = () => {
  return (
    <div className="aboutus2">
      <div className="a2left">
        <img className='t' src={abt} alt="" />
      </div>
      <div className="a2right">
        <p className="p3">About Us</p> 
        <h4><Typewriter
          options={{
            strings: ['We Provide Trusted Cab Service In The World'],
            autoStart: true,
            loop: true,
          }} ></Typewriter> </h4>
          <p className="p4">
          Welcome to our Smart cab Point! We are a team of
          passionate individuals dedicated to providing safe, reliable, and
          convenient transportation services to our customers.
        </p>
        <div className="morebtn2"><a href="/about#about1">Discover More</a></div>
      </div>
    </div>
  )
}

export default About