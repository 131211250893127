import React from 'react'
import "./Bookcab.css"
import TaxiForm from '../Home/Bookt_taxi/Taxiform'
 import Type from './Type/Type'


const TaxiBookForm = () => {

  
  return (
    <div>
    <div className='book-heading'>
         <h1>Book Cab Now</h1>
    </div>
    <div className='typewriter' id='form'>
   <h3><Type/></h3> 
    </div>
    <TaxiForm/>    
    <div className='map-container'>

          
    </div>
    </div>
  )
}

export default TaxiBookForm