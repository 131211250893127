import React, { useContext } from "react";
import "./Contact.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import img from "../../../Assets/Images/Drivers/d8.jpg";
import * as yup from "yup";
import Cdetails from "./Office_details/Cdetals";
import { Tooltip } from "@mui/material";
import MyContext from "../../Common/Context/MyContext";


const Contact = () => {
 
const{setOpen,setLoading,setMessage,loading} = useContext(MyContext)



  const formik = useFormik({
    initialValues: {
      yourname: "",
      youremail: "",
      yoursub: "",
      yourmessage: "",
    },
    validationSchema:  yup.object({
      yourname: yup
        .string("Enter your Name")
        .required("* Name is required")
        .min(4, "Name should be at least 4 characters")
        .matches(/^([^0-9]*)$/, "Don't enter numeric Value"),
  
      youremail: yup
        .string("Enter your email")
        .matches(/^\S*$/, "Email cannot contain whitespace")
        .email("Enter a valid email")
        .required("* Email is required"),
  
      yoursub: yup
        .string("Enter your Subject")
        .required("* Subject is required")
        .min(4, "Subject should be at least 4 characters")
        .matches(/^([^0-9]*)$/, "Don't enter numeric Value"),
  
      yourmessage: yup
        .string("Enter your Message")
        .required("* Message is required")
        .min(4, "Message should be at least 4 characters"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      const response = await fetch("https://backend-nine-vert.vercel.app/contact", {
        method: "POST",
        body: JSON.stringify(values),
        headers: {
          "Content-type": "application/json",
        },
      });

      const data = await response.json();

      if (data.success) {
        setOpen(true);
        setMessage(data.message)
      
        
        resetForm();
      } else {
        setMessage(data.error);
        setOpen(true)
       
      }

      setLoading(false); 
    },
  });

  return (
    <div>
      <div className="c1">
        <h1>Contact us </h1>
      </div>

      <div className="c3">
        <Cdetails />
        <div className="c-card">
          <div className="c-left">
            <img className="cimg" src={img} alt="" />
          </div>
          <form className="c-right" onSubmit={formik.handleSubmit}>
            <h2>Contact Here...</h2>
            <Box className="cbox">
            <Tooltip
                title="enter first name + middle name + surname"
                placement="top"
              >

              <TextField
                className="cboxfield"
                placeholder="Your Name"
                id="YourName "
                name="yourname"
                value={formik.values.yourname}
                onChange={formik.handleChange}
                error={
                  formik.touched.yourname &&
                  Boolean(formik.errors.yourname)
                }
                helperText={
                  formik.touched.yourname && formik.errors.yourname
                }
                variant="outlined"
              /></Tooltip>
                            <Tooltip
                title="e-mail"
                placement="top"
              >
              <TextField
                className="cboxfield"
                placeholder="Your Email"
                id="YourEmail"
                name="youremail"
                value={formik.values.youremail}
                onChange={formik.handleChange}
                error={
                  formik.touched.youremail &&
                  Boolean(formik.errors.youremail)
                }
                helperText={
                  formik.touched.youremail && formik.errors.youremail
                }
                variant="outlined"
              /></Tooltip>
            </Box>
            <Box className="cbox2">
            <Tooltip title="enter your subject" placement="top">
              <TextField
                className="cboxfield2"
                placeholder="Your Subject"
                id="yourSub"
                name="yoursub"
                value={formik.values.yoursub}
                onChange={formik.handleChange}
                error={
                  formik.touched.yoursub &&
                  Boolean(formik.errors.yoursub)
                }
                helperText={
                  formik.touched.yoursub && formik.errors.yoursub
                }
                variant="outlined"
              /></Tooltip>
                   <Tooltip title="enter your message" placement="top">
              <TextField
                className="form-control"
                placeholder="Type Message..."
                multiline
                id="exampleFormControlTextarea1"
                name="yourmessage"
                value={formik.values.yourmessage}
                onChange={formik.handleChange}
                error={
                  formik.touched.yourmessage &&
                  Boolean(formik.errors.yourmessage)
                }
                helperText={
                  formik.touched.yourmessage &&
                  formik.errors.yourmessage
                }
                rows="4"
              /></Tooltip>
              <Button
                className="cbtn"
                variant="contained"
                type="submit"
           
      
              >
                {loading ? 'sending...' : "Send Message"} 
              </Button>
            
            </Box>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
