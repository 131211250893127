import React from 'react'
import Slider from './Slider/Slider'
import About from './About/About'
import TaxiForm from './Bookt_taxi/Taxiform'
import Taxitype from './Taxi_type/Taxitype'
import Lost from './Lostthings/Lost'
import Feedback from './Feedback/Feedback'
import Count from './Count/Count'

const Home = () => {
  
  return (
    <div>
        <Slider/>
        <About/>
        <TaxiForm/>
        <Taxitype/>
        <Count/>
        <Lost/>
        <Feedback/>
    </div>
  )
}

export default Home