import React from "react";
import './Aboutus.css'
import img from '../../../Assets/Images/Drivers/d8.jpg'
import Driver from "./Driver/Driver";
import { useNavigate } from "react-router-dom";

const Aboutus = () => {
  const Navigate = useNavigate()
  return (
    <div><div className="abt">
        <h1>About us</h1>
    </div>
        
      <div className="aboutus">
        <div className="aright">
          <img src={img} alt="" />
        </div>
        <div className="aleft">
          <p className="p1">Few words about Smart Cab Point</p>
          <h1>Learn about Our Taxi Company</h1>
          <p className="p2">
            Welcome to our Smart cab Point! We are a team of
            passionate individuals dedicated to providing safe, reliable, and
            convenient transportation services to our customers.
          </p>
          <div className="morebtn" onClick={() =>Navigate('/about')} >Discover More</div>
        </div>
      </div>


      <Driver/>
              

    </div>
  );
};

export default Aboutus;
