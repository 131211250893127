import React from "react";
import CountUp from "react-countup";
import { FaTaxi } from "react-icons/fa6";
import { FaUsers } from "react-icons/fa";
import { FaUserNurse } from "react-icons/fa";
import { MdOutlineTaskAlt } from "react-icons/md";
import "./Count.css";

const Count = () => {
  return (
    <div className="count-main">
      <div className="counter1">
        <div className="count-icon">
          <span>
            <FaTaxi size={30} />
          </span>
        </div>
        <div className="count-cont">
          <h1>
            <CountUp
              start={0}
              end={600}
              duration={2.75}
              separator=" "
              decimals={0}
              decimal=","
              suffix="+"
            />
          </h1>
          <h3>Available Taxi</h3>
        </div>
      </div>

      <div className="counter1">
        <div className="count-icon">
          <span>
            <FaUsers size={30} />
          </span>
        </div>
        <div className="count-cont">
          <h1>
            <CountUp
              start={0}
              end={1000}
              duration={2.75}
              separator=" "
              decimals={0}
              decimal=","
              suffix="+"
            />
          </h1>
          <h3>Happy Clients</h3>
        </div>
      </div>

      <div className="counter1">
        <div className="count-icon">
          <span>
            <FaUserNurse size={30} />
          </span>
        </div>
        <div className="count-cont">
          <h1>
            <CountUp
              start={0}
              end={800}
              duration={2.75}
              separator=" "
              decimals={0}
              decimal=","
              suffix="+"
            />
          </h1>
          <h3>Our Drivers</h3>
        </div>
      </div>

      <div className="counter1">
        <div className="count-icon">
          <span>
            <MdOutlineTaskAlt size={30} />
          </span>
        </div>
        <div className="count-cont">
          <h1>
            <CountUp
              start={0}
              end={1200}
              duration={2.75}
              separator=" "
              decimals={0}
              decimal=","
              suffix="+"
            />
          </h1>
          <h3>Trips Completed</h3>
        </div>
      </div>
    </div>
  );
};

export default Count;