import React, { useEffect, useState } from 'react'
import MyContext from './MyContext'
import { useNavigate } from 'react-router-dom';

const MyContextProvider = ({children}) => {

    const Navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false); 
    const [message,setMessage] = useState('')
    const [startDestination, setStartDestination] = useState('');
    const [endDestination, setEndDestination] = useState('');
    const[account,setAccount] = useState(false)
    const[payment,setPayment] = useState(false)
    const[binfo,setBinfo] = useState([])
    const[dataload,setDataload] = useState(true)
    const [booking, setBooking] = useState(() => {
      const storedbookItems = sessionStorage.getItem('bookItems');
      return storedbookItems ? JSON.parse(storedbookItems) : {};
    });
    
    useEffect(() => {
      sessionStorage.setItem('bookItems', JSON.stringify(booking));
    }, [booking]);


    const [token,setToken]= useState('')

    useEffect(() => {
      const storedToken = sessionStorage.getItem('token');
      if (storedToken) {
        setToken(storedToken);
      }
    }, []);
  const[name,setName]= useState('')

  useEffect(() => {
    const storedName = sessionStorage.getItem('name');
    if (storedName) {
      setName(storedName);
    }
  }, []);
    const handleLogin = (newtoken,newname) =>{
      setToken(newtoken);
      setName(newname);
      sessionStorage.setItem('name', newname);
    sessionStorage.setItem('token', newtoken);
     
  }

  const handleLogout = () => {
    const confirmed = window.confirm('Are you sure you want to log out?');
    if (confirmed) {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('name');
      window.location.reload()
      setToken('');
      setName('')
      Navigate('/');
      setAccount(false)
      
    }
  };

  const [ldata,setLData]=useState([])

  useEffect(() => {
    const fetchLocationData = async () => {
      try {
        const response = await fetch('https://backend-nine-vert.vercel.app/api/location'); 
        if (!response.ok) {
          throw new Error('Failed to fetch location data');
        }
        const data = await response.json();
        setLData(data.data);
        console.log(data.data)
        setDataload(false);
      } catch (error) {
       alert("please try again")
        setDataload(false);
      }
    };

    fetchLocationData();
  }, []);
  
   

    const handleconfirm = async() =>{


      const Token = sessionStorage.getItem('token')
      try {
        setLoading(true);
        const response = await fetch('https://backend-nine-vert.vercel.app/book-cab', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Token}`
          },
          body: JSON.stringify({
            name:booking.name,
            email:booking.email,
            mobile:booking.mobile,
            selecttaxi:booking.selecttaxi,
            numofpass:booking.numofpass,
            pickuppoint:booking.pickuppoint,
            droppoint:booking.droppoint,
            pickupdate:booking.pickupdate,
            pickuptime:booking.pickuptime,
            numofbags:booking.numofbags,
            currentDate:new Date().toISOString()
          })
        });
    
        const data = await response.json()
    
        if (data.success) {
          setMessage(data.message)
          setOpen(true)
          setTimeout(() => {
            window.location.href = '/booking-details';
          }, 500);
         
        } else {
          setMessage(data.error)
          setOpen(true)
        }
      } catch (error) {
        setMessage('An error occurred, please try again');
        setOpen(true);
        console.error('Error during booking:', error);
      } finally {
        setLoading(false);
      }
    }


    const handlebookopen = (path) =>{

      window.open(`/${path}`,'_blank')
      setAccount(false)
      
      }

      
  return (
    <MyContext.Provider value={{endDestination,setEndDestination,startDestination,setStartDestination,handlebookopen,dataload,setDataload,binfo,setBinfo,payment,setPayment,handleconfirm,booking,setBooking,account,setAccount,name,handleLogin,Navigate,message,setMessage,open,setOpen,loading,setLoading,setToken,token,handleLogout,ldata,setLData}}>
        {children}
    </MyContext.Provider>
  )
}

export default MyContextProvider