import React, { useEffect, useState } from 'react';
import "./Taxitype.css";
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import Typewriter from "typewriter-effect";
import axios from 'axios';

const Taxitype = () => {
  const [activeData, setActiveData] = useState('Data1');

  const [data, setData] = useState([]);
  useEffect (() => {
      axios
      .get("https://backend-nine-vert.vercel.app/api/taxitype")
      .then((a) => setData(a.data));
  });


  const handleButtonClick = (dataName) => {
    setActiveData(dataName);
  };

 const  Data=data.slice(0,4)
 const  Data2=data.slice(4,8)
 const  Data3=data.slice(8,12)

  const dataToShow = activeData === 'Data1' ? Data :
    activeData === 'Data2' ? Data2 :
    activeData === 'Data3' ? Data3 :
      [];

  return (
    <div className='ourtaxi-container'>
      <h4>Our Taxi <LocalTaxiIcon fontSize='40px' /></h4>
      <h2>
        <Typewriter
          options={{
            strings: ['Lets check Available Taxi'],
            autoStart: true,
            loop: true,
          }}
        />
      </h2>
      <div className='ourtaxi-btn'>
        <div className='cbtn' onClick={() => handleButtonClick('Data1')}>Normal Taxi</div>
        <div className='cbtn' onClick={() => handleButtonClick('Data2')}>SUV Taxi</div>
        <div className='cbtn' onClick={() => handleButtonClick('Data3')}>Traveller Bus</div>
        {/* <div className='cbtn'>Limousine taxi</div>
        <div className='cbtn'>Traveller Bus</div> */}
      </div>
      <div className='taxi-type'>
        {dataToShow.map((item) => (
          <div className='onetaxi-type' key={item.id}>
            <img src={item.taxiimg} alt="" />
            <div className='rate'>
              <h6>Base Rate : {item.base_rate}₹</h6>
            </div>
            <div className='rate'>
              <h6>Per Km Rate : {item.per_km}₹</h6>
            </div>
            <div className='rate'>
              <h6>Class : {item.class}</h6>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Taxitype;