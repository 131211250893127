import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdManageAccounts } from "react-icons/md";
import "./Account.css";

const Account = () => {
  const [activeItem, setActiveItem] = useState(null);
  const Navigate = useNavigate();

  const handleItemClick = (id) => {
    setActiveItem(id);
    // Navigate to a new route based on the clicked item
    Navigate('/accountform' + id.toLowerCase());
  };

  return (
    <div>
      <div className="main-account">
        <div className="dashboard-container">
          <div onClick={() => handleItemClick('ACCOUNT')} className={`dashboard-item account ${activeItem === 'ACCOUNT' ? 'active' : ''}`}>
            <MdManageAccounts size={24}/>
            <h2 id='mobile'> ACCOUNT</h2>
          </div>
        </div>
        <div className="dashboard-container">
          <div onClick={() => handleItemClick('ACCOUNT')} className={`dashboard-item account ${activeItem === 'ACCOUNT' ? 'active' : ''}`}>
            <MdManageAccounts size={24}/>
            <h2 id='mobile'> ACCOUNT</h2>
          </div>
        </div>
        <div className="dashboard-container">
          <div onClick={() => handleItemClick('ACCOUNT')} className={`dashboard-item account ${activeItem === 'ACCOUNT' ? 'active' : ''}`}>
            <MdManageAccounts size={24}/>
            <h2 id='mobile'> ACCOUNT</h2>
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default Account;
