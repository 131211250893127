import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Nopage.css';

const Nopage = () => {
    const navigate = useNavigate();

    const goToHome = () => {
        navigate('/');
    };

    return (
        <div className='b'>
            <h1>404</h1>
            <h3>Sorry, this page isn't available</h3>
            <div className='btn' onClick={goToHome} style={{ border: '1px solid black', padding: '5px' }}>
                <h6>GO TO HOME</h6>
            </div>
        </div>
    );
};

export default Nopage;