import React from "react";
import "react-multi-carousel/lib/styles.css";
import "./Slider.css";
import img from "../../../../Assets/Images/Slider_imgs/s1.jpg";
import img2 from "../../../../Assets/Images/Slider_imgs/s8.jpg";
import img4 from "../../../../Assets/Images/Slider_imgs/s4.jpg";
import img7 from "../../../../Assets/Images/Slider_imgs/s7.jpg";
import { Zoom } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const Slider = () => {
  const images = [img, img2, img4, img7];




  return (
    <Zoom
      scale={1.4}
      arrows={false}
      duration={2000}
      transitionDuration={1000}
      pauseOnHover={false}
   
    >
      {images.map((each, index) => (
        <div key={index} style={{ width: "100%" }} className="slider-main">
          <img
            style={{ objectFit: "cover" }}
            alt="Slide"
            src={each}
         
          />
        </div>
      ))}
    </Zoom>
  );
};

export default Slider;