import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './Components/Common/Header/Header';
import Home from './Components/Pages/Home/Home';
import Footer from './Components/Common/Footer/Footer';
import Aboutus from './Components/Pages/Aboutus/Aboutus';
import Service from './Components/Pages/Service/Service';
import Contact from './Components/Pages/Contact/Contact';
import Bookcab from './Components/Pages/Book_cab/Bookcab';
import Wishlist from './Components/Pages/Wish_list/Wishlist';
import Login from './Components/Pages/LoginRegister/Login';
import Register from './Components/Pages/LoginRegister/Register';
import ScrollToTop from 'react-scroll-to-top';
import Loader from './Components/Common/Loader/Loader';
import MyContextProvider from './Components/Common/Context/MyContextProvider';
import Registervehicle from './Components/Pages/Registervehicle/Registervehicle';
import Nopage from './Components/Pages/Nopage/Nopage';
import Account from './Components/Common/Account/Account';
import AccountForm from './Components/Common/Account/Accountform/Accfrm';
import { PiArrowFatLinesUpBold } from 'react-icons/pi';
import GoToTop from './Components/Common/GoToTop';
import Moda from './Components/Common/Modal/Modal';
import Confirm from './Components/Common/ConfirmPage/ComfirmPage';
import Pmodal from './Components/Common/PaymentModal/Pmodal';
import Booking from './Components/Common/Booking/Booking';


function App() {

 



  return (
    <div>
      <BrowserRouter>
     

        <MyContextProvider>
        <Loader />
        <Moda/>
        <Pmodal/>
        <GoToTop/>
          <Header />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/booking-details' element={<Booking />} />
            <Route path='/about' element={<Aboutus />} />
            <Route path='/confirm' element={<Confirm />} />
            <Route path='/service' element={<Service />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/book_cab' element={<Bookcab />} />
            <Route path='/wishlist' element={<Wishlist />} />
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/register-vehicle' element={<Registervehicle />} />
            <Route path='/account' element={<Account />} />
            <Route path='/accountform' element={<AccountForm />} />
            <Route path='*' element={<Nopage />} />
          </Routes>
        
          <Footer />
       

          <ScrollToTop smooth component={<PiArrowFatLinesUpBold/>} />
        </MyContextProvider>

      </BrowserRouter>
    </div>
  );
}

export default App;
