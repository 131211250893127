import React, { useContext } from 'react'
import './Pmodal.scss'
import Payment from '../../../PaymentPage/Payment'
import MyContext from '../Context/MyContext'

const Pmodal = () => {

    const {payment,setPayment} = useContext(MyContext)
  return (
  <>

  {
    payment &&
    <div  class="p-modal">
        <div class="modal-content">
            <span class="close" onClick={() => setPayment(false)}>&times;</span>
           <Payment/>
        </div>
    </div>

  }

    </>

  )
}

export default Pmodal