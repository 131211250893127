import React, { useState, useEffect } from "react";
import "./Lost.css";
import img from "../../../../Assets/Images/Lostthings/step1.jpg";
import img2 from "../../../../Assets/Images/Lostthings/step2.jpg";
import img3 from "../../../../Assets/Images/Lostthings/step3.jpg";
import { FaRegSmile } from "react-icons/fa";

const Lost = () => {
  const [height, setHeight] = useState("0px");

  const handlescroll = () => {
    const scroll = window.scrollY;

    if (scroll > 2300) {
      setHeight("100%");
    } else {
      setHeight("0px");
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handlescroll);
  });
  return (
    <div className="lost-container">
      <h4>Lost Things</h4>
      <p>Did you lose something in one of our taxis?</p>
      <div className="img-container">
        <div>
          <img src={img} alt="" />
          <h6>1. Remember when did you lose it</h6>
        </div>
        <div>
          <img src={img2} alt="" />
          <h6>2. Contact us via email or call us</h6>
        </div>
        <div>
          <img src={img3} alt="" />
          <h6>3. Come to our storage and pick your item</h6>
        </div>
      </div>
      <p>
        We value our clients deeply and we want you to be happy{" "}
        <span>
          <FaRegSmile />
        </span>
      </p>
      <div className="lost-btn">
        <a href="/contact"> Contact Us Now</a>
      </div>
      <div className="lost-over" style={{ height }}>
        <div className="ovrly-h">
          <h4>Lost Things</h4>
          <p>Did you lose something in one of our taxis?</p>
        </div>
        <div className="img-container">
          <div>
            <img src={img} alt="" />
            <h6>1. Remember when did you lose it</h6>
          </div>
          <div>
            <img src={img2} alt="" />
            <h6>2. Contact us via email or call us</h6>
          </div>
          <div>
            <img src={img3} alt="" />
            <h6>3. Come to our storage and pick your item</h6>
          </div>
        </div>
        <p>
          We value our clients deeply and we want you to be happy{" "}
          <span>
            <FaRegSmile />
          </span>
        </p>
        <div className="lost-btn">
          <a href="/contact"> Contact Us Now</a>
        </div>
      </div>
    </div>
  );
};

export default Lost;