import React, { useContext } from "react";
import LocalTaxiIcon from "@mui/icons-material/LocalTaxi";
import MenuIcon from "@mui/icons-material/Menu";
import Drawerreact from "./Drawerreact";
import { IoIosCall} from "react-icons/io";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import { FaCircleInfo } from "react-icons/fa6";
import "./Header.css";
import { CiLogin } from "react-icons/ci";
import { FaCar } from "react-icons/fa";
import MyContext from "../Context/MyContext";
import { FaCarAlt } from "react-icons/fa";
import { LuUserCheck } from "react-icons/lu";
import { CiLogout } from "react-icons/ci";


const Header = () => {

  const Token = sessionStorage.getItem('token')
  const Name = sessionStorage.getItem('name')

  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const {Navigate,account,setAccount,handleLogout,handlebookopen} = useContext(MyContext)

  return (
    <>
      <div className="navbar">
        <div className="logo" onClick={() => Navigate("/")}>
       
            Smart <span>Cab</span> Point
      
        </div>
        <div className="list">
          <li className="l" onClick={() => Navigate("/About")}>
            <span>
              <FaCircleInfo />
            </span>
            About
          </li>
          <li className="l" onClick={() => Navigate("/service")}>
            <span>
              <MdOutlineMiscellaneousServices size={25} />
            </span>
            Service
          </li>
          <li className="l" onClick={() => Navigate("/contact")}>
            <span>
              <IoIosCall size={25} />
            </span>
            Contact
          </li>
          <li onClick={() => Navigate("/book_cab")}>
            <div className="bookbtn">
              <LocalTaxiIcon size={25} /> Book Cab
            </div>
          </li>
        </div>
        <div className="icon">
          <li  onClick={() => Navigate("/register-vehicle")}>
          
              <span>
                <FaCar />
              </span>
              <p>vehicle</p>
       
          </li>
      
       
             
         
        
         
         {Token ?<li  onClick={() => setAccount(!account)}>
         <span><CiLogin/></span><p style={{fontWeight:400,fontSize:'18px'}}>{Name}</p>
      </li>:
          
          <li  onClick={() => Navigate('/login')}>
             <span><CiLogin/></span><p>Login</p>
     
          </li>}

          <li  onClick={toggleDrawer}>
            <span><MenuIcon /></span>
          </li>
        </div>
      </div>

      <div className="drawer">
        <Drawerreact open={isOpen} onclose={toggleDrawer} />
      </div>

      {account &&

      <div class="search-list">
    
      <li>
        <span>{<LuUserCheck/>}</span>
        <span class="text">Hello! {Name}</span></li>
      <li onClick={() => handlebookopen('booking-details')}>
        <span><FaCarAlt /></span>
        <span class="text">Booking Details</span>
      </li>
      <li onClick={handleLogout}>
        <span><CiLogout/></span>
        <span class="text">Logout</span>
      </li>
  
     
     </div>
      }
    </>
  );
};

export default Header;